<script setup lang="ts">
import Multiselect from '@vueform/multiselect'
import {
  useSearchInputData,
  type AlgoliaSearchResponse,
} from '~/composables/useSearchInputData'
import type {
  Locale,
  SearchHouse,
  MutilpleIndicesGenericType,
} from 'lc-services/types'
import type { LocationQuery } from 'vue-router'
import type { InputSearchQuery } from '~/types/search/types'

type SearchQuerySerializedResponse = {
  type: string
  value: number
  label: string
  slug: string
}[]

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    hasClearButton?: boolean
    hasIcon?: boolean
    hasSearchButton?: boolean
    isTaggable?: boolean
    location?: string
    modelValue?: InputSearchQuery | InputSearchQuery[]
    triggerFocus?: boolean
  }>(),
  {
    disabled: false,
    hasClearButton: true,
    hasIcon: true,
    hasSearchButton: false,
    isTaggable: false,
    location: '',
    modelValue: () => ({}) as InputSearchQuery,
    triggerFocus: false,
  },
)
const emits = defineEmits<{
  'clear-query': []
  'close-select': []
  'open-select': []
  'refine-by-query': [Record<string, string | number>, string] | [LocationQuery]
  'remove-option': [unknown]
  'update:model-value': [string]
}>()

const { isMobile } = useDevice()
const { t, locale } = useI18n()
const config = useRuntimeConfig()
const localePath = useLocalePath()
const router = useRouter()
const { isSmallScreen } = useBreakpoint()
const { userIsAdmin, userIsPartner } = useAuth()
const { trackEvent } = useTracking()

const currentSearchQuery = ref('')
const destinations = ref<SearchQuerySerializedResponse>([])
const hasNoQuery = ref(true)
const houses = ref<SearchQuerySerializedResponse>([])
const isClosed = ref(true)
const selectOpened = ref(false)
const oldValue = ref('')
const indexDestinations = ref(
  `${config.public.algoliaIndexPrefix}_public_destinations`,
)
const indexHouses = ref(
  `${config.public.algoliaIndexPrefix}_public_houses_is_pinned_house_desc`,
)
const searchInput = ref<HTMLInputElement | null>(null)
const topDestinationIds = ref([84, 17, 101, 40, 27, 70])
const multiselectRef = ref<InstanceType<typeof Multiselect>>()

const optionsIsEmpty = computed(() => destinationsAndHouses.value.length === 0)
const destinationsAndHouses = computed(() =>
  hasNoQuery.value
    ? [
        {
          label: t('search.top_destis'),
          options: destinations.value,
        },
      ]
    : [
        {
          label: t('search.destinations'),
          options: destinations.value,
        },
        {
          label: t('search.homes'),
          options: houses.value,
        },
      ],
)
const topDestinationIdsQuery = computed(() =>
  topDestinationIds.value.map((x) => `id=${x}`).join(' OR '),
)
const displayPlaceholder = computed(
  () =>
    !props.isTaggable ||
    (props.isTaggable &&
      (Array.isArray(props.modelValue)
        ? !props.modelValue.length
        : !props.modelValue.value)),
)
const activeDestination = computed(
  () =>
    (Array.isArray(props.modelValue)
      ? props.modelValue.length
      : props.modelValue?.label) ||
    !displayPlaceholder.value ||
    selectOpened.value,
)

onMounted(() => {
  setIndexForAlgolia()

  if (props.triggerFocus && multiselectRef.value) {
    multiselectRef.value.open()
    multiselectRef.value.focus()
  }
})

const closeSelect = () => {
  emits('close-select')
  selectOpened.value = false

  if (props.location === 'homepage') {
    isClosed.value = true
    oldValue.value = currentSearchQuery.value
  }
}
const refine = () => {
  const [destination] = destinations.value
  const [house] = houses.value
  const searchQuery = oldValue.value

  if (!searchQuery.length) {
    trackSearchEmpty()
  }

  if (props.location === 'homepage') {
    if (props.modelValue) {
      const path = localePath({
        name: 'search',
      })
      router.push({ path })
    }
  }

  if (destination) {
    selectOption(destination)
  } else if (house) {
    selectOption(house)
  }
}
const removeOption = (removedOption: unknown) => {
  emits('remove-option', removedOption)
}
const searchQuery = (value: string) => {
  currentSearchQuery.value = value

  if (value?.length >= 2) {
    hasNoQuery.value = false
    searchQueryOnAlgolia(value)
    emits('update:model-value', value)
  } else {
    hasNoQuery.value = true
    searchQueryOnAlgolia()
  }
}
const setIndexForAlgolia = () => {
  const indexType = userIsPartner.value ? '_b2b2c_' : '_admin_'

  if (userIsAdmin.value || userIsPartner.value) {
    indexHouses.value = indexHouses.value.replace('_public_', indexType)
  }
}
const openSelect = () => {
  emits('open-select')
  selectOpened.value = true

  if (props.location === 'homepage') {
    isClosed.value = false
    emits('update:model-value', '')
  }

  searchQueryOnAlgolia()

  if (
    searchInput.value &&
    isSmallScreen.value &&
    props.location === 'homepage'
  ) {
    setTimeout(() => {
      const bodyTopPosition = document.body?.getBoundingClientRect()?.top
      const inputTopPosition = searchInput.value?.getBoundingClientRect()?.top

      if (bodyTopPosition && inputTopPosition) {
        window.scrollTo({
          top: inputTopPosition - bodyTopPosition - 10,
          behavior: 'smooth',
        })
      }
    }, 250)
  }
}
const searchQueryOnAlgolia = async (newValue = '') => {
  let query = newValue

  if (isClosed.value && props.location === 'homepage') {
    query = oldValue.value
  }

  const maxResult = isMobile ? 4 : 6
  const hitsPerPage = hasNoQuery.value ? 6 : maxResult
  let filtersHouseCount = 'NOT houses_count=0 AND NOT state:hidden'

  if (userIsAdmin.value) {
    filtersHouseCount =
      'NOT state:offboarded AND NOT state:on_hold AND NOT state:onboarding AND NOT houses_count=0'
  }

  const filtersDestiHouseCount = hasNoQuery.value
    ? `${filtersHouseCount} AND ${topDestinationIdsQuery.value}`
    : filtersHouseCount

  const results = await useSearchInputData({
    indexDestinations,
    indexHouses,
    locale,
    config,
  }).searchQueryOnAlgoliaData<SearchHouse | MutilpleIndicesGenericType>({
    filtersDesti: filtersDestiHouseCount,
    filtersHouse: filtersHouseCount,
    hitsPerPage,
    query,
  })

  const { houseCount, destiCount } = calculateCountOfResult(results, maxResult)
  const housesResult = results as AlgoliaSearchResponse<SearchHouse>[]
  const destinationsResult =
    results as AlgoliaSearchResponse<MutilpleIndicesGenericType>[]

  houses.value = serializeHousesResult(housesResult).slice(0, houseCount) || []
  destinations.value = (
    hasNoQuery.value
      ? serializeDestinationsResult(destinationsResult)
          .reduce<SearchQuerySerializedResponse>((acc, d) => {
            acc[topDestinationIds.value.indexOf(Number(d.value))] = d
            return acc
          }, [])
          .slice(0, maxResult)
      : serializeDestinationsResult(destinationsResult).slice(0, destiCount)
  ).filter((d) => d.value)

  if (!houses.value.length && !destinations.value.length)
    trackEvent({
      event: 'search_without_results',
      search_query: query,
    })
}

const calculateCountOfResult = (
  results: AlgoliaSearchResponse[],
  maxResult: number,
) => {
  let houseCount
  let destiCount
  const destinationLength =
    results.find((result) => result.index === indexDestinations.value)?.hits
      ?.length ?? 0
  const housesLength =
    results.find((result) => result.index === indexHouses.value)?.hits
      ?.length ?? 0

  if (housesLength === maxResult && destinationLength === maxResult) {
    houseCount = maxResult / 2
    destiCount = maxResult / 2
  } else if (housesLength < maxResult) {
    houseCount = housesLength
    destiCount = maxResult - houseCount
  } else if (destinationLength < maxResult) {
    destiCount = destinationLength
    houseCount = maxResult - destiCount
  }

  return {
    houseCount,
    destiCount,
  }
}

const serializeDestinationsResult = (
  results: AlgoliaSearchResponse<MutilpleIndicesGenericType>[],
) =>
  results
    .filter((result) => result.index === indexDestinations.value)
    .reduce<SearchQuerySerializedResponse>(
      (_, result) =>
        result.hits.map((desti) => ({
          type: 'location',
          value: Number(desti.id),
          slug: '',
          label: desti.clusterized_name[locale.value as Locale],
        })),
      [],
    )
const serializeHousesResult = (results: AlgoliaSearchResponse<SearchHouse>[]) =>
  results
    .filter((result) => result.index === indexHouses.value)
    .reduce<SearchQuerySerializedResponse>(
      (_, result) =>
        result.hits.map((house) => ({
          type: 'type-of-house',
          value: house.id,
          label: house.name,
          slug: house.slug[locale.value as Locale],
        })),
      [],
    )

const trackSearchEmpty = () => {
  const tracking = {
    category: 'Homepage',
    event: 'search_started',
    source: 'homepage',
    suggestion: 'default',
  }

  trackEvent(tracking)
}

const selectOption = (selectedOption: Record<string, string | number>) => {
  emits('refine-by-query', selectedOption, currentSearchQuery.value)
}

const clearQuery = () => {
  emits('clear-query')
}
</script>

<template>
  <div ref="SearchInput" :class="['search-input__wrap', location]">
    <div ref="searchInput" class="search-input">
      <BaseIcon
        v-if="hasIcon"
        :color="activeDestination ? 'text-gray-700' : 'text-gray-400'"
        name="pinAlt"
        class="search-input__icon"
      />

      <multiselect
        id="search-input"
        ref="multiselectRef"
        :caret="false"
        :class="[
          'w-full',
          {
            'pl-8': hasIcon,
          },
        ]"
        :disabled="disabled"
        :filterResults="false"
        :group-select="false"
        :mode="isTaggable ? 'tags' : 'single'"
        :model-value="modelValue"
        :no-options-text="$t('field.placeholder.noOptions')"
        :no-results-text="$t('field.placeholder.noResult')"
        :object="true"
        :options="destinationsAndHouses"
        :placeholder="displayPlaceholder ? $t('search.placeholder') : ''"
        autocomplete="off"
        data-cy="search-input"
        groups
        searchable
        track-by="label"
        @clear="clearQuery"
        @close="closeSelect"
        @open="openSelect"
        @deselect="removeOption"
        @search-change="searchQuery"
        @select="selectOption"
      >
        <template #placeholder>
          <div :class="['multiselect-placeholder', { 'pl-4': hasIcon }]">
            <span
              :class="[
                'w-max',
                {
                  'text-gray-400': Array.isArray(modelValue)
                    ? !modelValue.length
                    : !modelValue.value,
                  'text-gray-700': Array.isArray(modelValue)
                    ? modelValue.length
                    : modelValue.value,
                },
              ]"
            >
              {{ displayPlaceholder ? $t('search.placeholder') : '' }}
            </span>
          </div>
        </template>

        <template #singlelabel="{ value }">
          <div class="multiselect-single-label w-full">
            <template v-if="value">
              <span
                class="multiselect-label-span w-[calc(100%-35px)] flex-none truncate"
              >
                {{ value.label }}
              </span>
            </template>
            <template v-else-if="modelValue">
              <span class="flex-none overflow-hidden">
                {{
                  Array.isArray(modelValue)
                    ? modelValue[0].label
                    : modelValue.label
                }}
              </span>
            </template>
            <label
              v-else-if="userIsAdmin && optionsIsEmpty"
              class="m-0 flex-none overflow-hidden text-base font-normal leading-5 text-gray-500"
              for="search-input"
            >
              {{ $t('search.placeholder') }}
            </label>
            <label
              v-else
              class="m-0 flex-none overflow-hidden text-base font-normal leading-5 text-gray-500"
              for="search-input"
            >
              {{ $t('search.placeholder') }}
            </label>
          </div>
        </template>

        <template #grouplabel="{ group }">
          <span
            :class="[
              'flex bg-white py-4 text-md font-bold leading-6 text-gray-400',
              { 'px-4': hasIcon },
              { 'px-8': !hasIcon },
            ]"
          >
            {{ group.label }}
          </span>
        </template>

        <template #option="{ option }">
          <div
            :class="[
              'flex items-center whitespace-normal py-2 leading-6',
              { 'px-4': hasIcon },
              { 'pl-8 pr-0': !hasIcon },
            ]"
          >
            <BaseIcon
              :name="option.type === 'type-of-house' ? 'home' : 'pinAlt'"
              :size="1.5"
              class="mr-4"
            />
            {{ option.label }}
          </div>
        </template>

        <template #clear="{ clear }">
          <button class="absolute right-4 z-10 lg:right-2" type="button">
            <BaseIcon
              color="text-gray-700"
              name="close"
              :size="1"
              @click="clear"
            />
          </button>
        </template>

        <template #tag="{ option, handleTagRemove }">
          <div
            class="my-1 mr-2 inline-block rounded-full bg-primary-800 px-4 py-2 text-md leading-4 text-white"
          >
            <div class="flex items-center">
              <span class="mr-2.5">{{ option.label }}</span>
              <base-button
                class="!bg-transparent"
                has-icon
                no-padding
                @click="handleTagRemove(option, $event)"
              >
                <BaseIcon name="cancel" />
              </base-button>
            </div>
          </div>
        </template>
      </multiselect>

      <base-button
        v-if="hasSearchButton"
        :aria-label="$t('search.search')"
        class="base-search__btn"
        color="secondary"
        data-cy="search-button"
        @click="refine"
      >
        <BaseIcon v-if="$device.isMobile" name="search" />
        <span v-else>{{ $t('search.search') }}</span>
      </base-button>
    </div>
  </div>
</template>

<style>
:root {
  --ms-option-py: 0.5rem;
  --ms-option-px: 2rem;
  --ms-group-label-px: 2rem;
  --ms-group-label-py: 0.5rem;
}

.search-input__wrap {
  @apply relative;
}
.search-input__wrap.homepage {
  @apply border-0;
}
.search-input__wrap.homepage .search-input {
  @apply flex;
}
.search-input__wrap .base-multiselect .multiselect__tags {
  min-height: 51px;
  height: unset;
}
.search-input__wrap.homepage .base-multiselect .multiselect__tags {
  height: 58px;
  box-shadow: unset;
  @apply rounded-none pl-8 flex items-center flex-wrap border-0;
}
.search-input__wrap.homepage .base-search__btn {
  @apply absolute top-0 bottom-0 right-0 rounded-none z-dropdown;
}
.search-input .multiselect-group-label {
  @apply bg-white text-gray-400 p-0;
}
.search-input .multiselect-option {
  @apply p-0;
}
.search-input__wrap .multiselect-dropdown {
  padding: 0 0 1rem 0;
}
@screen md {
  .search-input__wrap .multiselect-dropdown {
    max-height: min-content !important;
  }
}
.search-input .search-input__icon {
  transform: translateY(-50%);
  @apply absolute left-4 top-1/2 z-20;
}

.homepage.search__input .multiselect-search,
.homepage.search__input .multiselect-placeholder {
  padding-left: 2rem;
}
</style>
